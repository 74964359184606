.form-group {
  margin: 0 auto;
  max-width: 1300px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  flex-direction: column;
  font-size: 18px;
  padding: 20px;
}

.form-group-line {
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-group-line p {
  color: #ff0000;
}

.input-required {
  color: #ff0000;
  font-size: 16px;
  font-weight: bold;
}

.form-group input,
textarea {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
  font-size: 18px;
}

.form-group button {
  display: block;
  width: 100px;
}

.form-group .error {
  background-color: #f1c40f;
  border-radius: 5px;
  padding: 20px;
  color: #fff;
}

.form-group .success {
  background-color: #23b89a;
  border-radius: 5px;
  padding: 20px;
  color: #fff;
}

@media screen and (max-width: 960px) {
  .form-group {
    width: 100%;
    padding: 10px;
  }
}