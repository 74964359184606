@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* border: #000 1px solid; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.desc-content ul {
  list-style: initial;
  margin: initial;
  padding: 0 0 0 40px;
}

.desc-content li {
  list-style: disc;
  display: list-item;
}

/* .page-content {
  margin-top: 120px;
} */

/* selction style */
::selection {
  background: #999;
  color: #fff;
}

::-moz-selection {
  background: #999;
  color: #fff;
}

::-webkit-selection {
  background: #999;
  color: #fff;
}

.mb10 {
  margin: 10px;
}

.mb15 {
  margin: 15px;
}

.mb20 {
  margin: 20px;
}

.common-button {
  width: 100%;
  max-width: 200px;
  padding: 0.6rem 1rem;
  background-color: #19282f;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  border: #19282f 2px solid;
  border-radius: 10px;
}

.common-button:hover {
  background-color: #fff;
  color: #000;
}

.white-button {
  padding: 0.6rem 1rem;
  background-color: #fff;
  color: #19282f;
  cursor: pointer;
  font-size: 18px;
  border: #fff 2px solid;
  font-weight: 400;
}

.pagination {
  width: 100%;
  display: inline-block;
}

.pagination ul {
  display: flex;
  width: 100%;
}

.pagination ul>li>a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  cursor: pointer;
}

.pagination .selected a {
  font-weight: bold;
  background-color: #19282f;
  color: white;
}

.pagination ul>li>a:hover {
  font-weight: bold;
  background-color: #ddd;
}

.pagination .previous,
.next {
  background-color: #ddd;
}

.pagination .disabled {
  background-color: #ddd;
}

.pagination .break {
  background-color: none;
}

.my-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.my-table td,
.my-table th {
  border: 1px solid #ddd;
  padding: 8px;

}

.my-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.my-table tr:hover {
  background-color: #ddd;
}

.my-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #B33030;
  color: white;
  width: 50%;
}

.my-table td {
  text-align: center;
}

.ql-editor {}

.ql-editor ol li {
  list-style-type: decimal !important;
}

.ql-editor h2 {
  display: block;
  font-size: 1.5em;
  /* 默认是相对于 body 字体大小的 1.5 倍 */
  font-weight: bold;
  /* 加粗 */
  margin-block-start: 0.83em;
  /* 上边距 */
  margin-block-end: 0.83em;
  /* 下边距 */
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  unicode-bidi: isolate;
}

.ql-editor h1 {
  display: block;
  font-size: 2em;
  /* 默认是 body 字体大小的 2 倍 */
  font-weight: bold;
  /* 加粗 */
  margin-block-start: 0.67em;
  /* 上边距 */
  margin-block-end: 0.67em;
  /* 下边距 */
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  unicode-bidi: isolate;
}

@media screen and (max-width: 960px) {
  .pagination ul {
    flex-wrap: wrap;
  }
}