.common-title-row {
  margin: 0 auto;
  padding-top: 50px;
  text-align: center;
}

.common-title-row h3 {
  font-size: 30px;
  color: #000;
  margin-bottom: 20px;
  font-weight: bold;
}

.common-title-row h3 span {
  display: block;
  width: 100px;
  line-height: 20px;
  border-bottom: #000 1px solid;
  margin: 0 auto;
  height: auto;
}

.common-title-row p {
  font-size: 20px;
  color: #fff;
}

.about-question {
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  background-color: #f7f7f7;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.about-question .list ul {
  margin: 0 auto;
  width: 80%;
  font-size: 18px;
  color: #000;
}

.about-question .list ul li {
  list-style-type: none;
  border-bottom: 1px solid #000;
  line-height: 50px;
  text-align: left;
  cursor: pointer;
}


.about-question-plus {
  display: inline-block;
  background-color: #000;
  color: #fff;
  margin-right: 15px;
  font-weight: bold;
  font-size: 25px;
  width: 30px;
  height: 30px;
  text-align: center;
  position: relative;
  top: 5px;
  line-height: 30px;
}

.about-question .list ul li>p {
  line-height: 25px;
  color: #000;
  display: none;
  padding: 10px;
}

.about-question .list p {
  color: #000;
}

@media screen and (max-width: 960px) {
  .about-question .list ul {
    width: 95%;
  }
}