.service-wrap {
    margin: 0 auto;
    max-width: 1280px;
    display: flex;
    gap: 50px;
    padding: 20px;
    /* justify-content: center; */
}

.service-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.service-header {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.service-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 30px;
    /* Adjust the gap between items as needed */
    padding: 10px;
}

.service-card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 500px;
    border-radius: 20px;
    /* border: #ccc solid 1px; */
    padding: 15px;
    overflow: hidden;
}

.service-card p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden;
}

.service-card:hover p {
    -webkit-line-clamp: 9;
}

.service-card h3 {
    font-size: 2rem;
    font-weight: 600;
}

.service-card:hover {
    cursor: pointer;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.service-card:hover img {
    /* 向上消失 */
    transform: translateY(-120px);
    display: none;
    /* animation: fadeOut 0.5s; */
}

.service-card img {
    border-radius: 20px;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@media screen and (max-width: 960px) {
    .service-wrap {
        width: 100%;
    }

    .service-list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        gap: 30px;
    }

    .service-card img {
        width: 100%;
    }
}